<template>
    <div class="search-bar" :class="{ 'is-input-focused' : isInputFocused, 'is-static-search': isStaticSearch, 'is-mobile' : isMobile }">
        <div class="search-input">
            <img class="search-icon" src="@assets/img/icons/search.svg">
            <input
                v-model="userInput"
                type="text"
                @focus="isInputFocused = true"
                @blur="onInputBlur"
                @keyup="asyncfetchSuggestions"
                @keyup.enter="search(userInput)"
            >
        </div>
        <dropdown-list
            v-if="suggestionsList.length"
            :is-fetching-suggestions="isFetchingSuggestions"
            :title="suggestionsTitle"
            :list="suggestionsList"
            @clicked-suggestion="search"
        />
    </div>
</template>

<script>
import debounce from "lodash/debounce";

export default {
    name: "SearchBar",
    components: {
        DropdownList: () => import(/* webpackChunkName: "dropdown-list" */ "./dropdown-list")
    },
    props: {
        isStaticSearch: {
            type: Boolean,
            default: false
        },
        isMobile: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            userInput: "",
            isInputFocused: false,
            isFetchingSuggestions: false,
            suggestionsTitle: "Recents",
            suggestionsList: [],
            oldUserInput: ""
        };
    },
    created() {
        this.asyncfetchSuggestions = debounce(this.fetchSuggestions, 500);
        if (this.isStaticSearch && this.$route.params.searchString) {
            this.userInput = this.$route.params.searchString;
            this.oldUserInput = this.$route.params.searchString;
        }
    },
    methods: {
        fetchSuggestions() {
            if (this.userInput != "" && this.userInput != this.oldUserInput) {
                this.isFetchingSuggestions = true;
                axios.get(`/search/${this.userInput}/suggestion`).then(({ data }) => {
                    this.suggestionsList = data.results.documents;
                    this.suggestionsTitle = "Suggested topics";
                    this.oldUserInput = this.userInput;
                }).catch((error) => {
                    console.log(error.response.data.message);
                }).finally(() => {
                    this.isFetchingSuggestions = false;
                });
            }
        },
        search(searchString) {
            if (searchString != "" && (searchString != this.$route.params.searchString)) {
                this.userInput = searchString;

                if (this.$route.name.includes("web-app-search")) {
                    this.$router.push({ name: this.$route.name, params: { searchString } });
                } else {
                    this.$router.push({ name: "web-app-search", params: { searchString } });
                }

                const element = document.querySelector(".search-input input");
                element.blur();
            }
        },
        onInputBlur() {
            setTimeout(() => {
                this.isInputFocused = false;
                this.suggestionsTitle = "Recents";
                if (this.suggestionsList.length) {
                    document.querySelector(".dropdown-list").scrollTo(0, 0);
                }
            }, 350);
        }
    }
}
</script>

<style lang="scss" scoped>
.search-bar {
    position: relative;

     &::before {
        background-color: rgba(0, 0, 0, 0);
        transition: all .3s;
        content: "";
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
    }

    .search-icon {
        position: absolute;
        left: 15px;
        top: 50%;
        transform: translateY(-50%);
    }

    .search-input {
        position: relative;
        z-index: 3;

        input {
            @extend .body-text;
            height: 38px;
            width: 180px;
            border-radius: 30px;
            background-color: #0F0F10;
            box-shadow: none;
            color: white;
            border: 1px solid #38383A;
            padding-left: 45px;
            padding-right: 15px;
            transition: width .3s;

            &:focus {
                outline: 0;
            }
        }
    }

    .dropdown-list {
        height: 0;
        opacity: 0;
        padding: 0;
        transition: all .3s;
        transition-delay: 0s;
    }

    &.is-input-focused {
        .search-input {
            input {
                width: 390px;
            }
        }

        .dropdown-list {
            height: 405px;
            opacity: 1;
            padding: 30px 15px;
            transition-delay: .3s;
        }
    }

    &.is-static-search {
        width: 100%;

        .search-icon {
            width: 30px;
        }

        .search-input {
            input {
                width: 100%;
                padding-right: 0;
                padding-left: 60px;
                border: 0;
                font-size: 26px;
                background-color: transparent;
            }
        }

        .dropdown-list {
            display: none;
        }
    }

    &.is-mobile {
        width: 100%;
    }

    &.is-mobile.is-input-focused {
        /deep/ .dropdown-list {
            height: initial;
            opacity: 1;
            background-color: transparent;
            border: 0;
            z-index: 1;
            position: relative;

            .loader-container {
                background-color: rgba(0, 0, 0, .9);
            }
        }

        &::before {
            position: fixed;
            background-color: rgba(0, 0, 0, 1);
            z-index: 1;
        }
    }
}
</style>
